export function setStorage(sname,svalue) {
   return localStorage.setItem(sname, svalue);
}

export function getStorage(sname) {
   return localStorage.getItem(sname);
}

export function deleteStorage(){
    localStorage.clear();
    return true;
}