import React from "react";
import styles from "../LoadingDialog/dialog.module.css";

const LoadingDialog = ({ intentData }) => {
  return (
    <div
      className={[
        styles.dialogView,
        intentData ? styles.dialogViewActive : "",
      ].join(" ")}
    >
      <div className={[styles.dialogViewContents]}>
        <div className={[styles.dialogViewContentsLoading]}></div>
        <p>Loading</p>
      </div>
    </div>
  );
};

export default LoadingDialog;
