import React from 'react'
import '../../MainStyle.css';

const wingoRuleDialog = ({intentData,updateState}) => {

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg ${intentData.ruleDialogShow ? 'activeDialog' : ''}`} onClick={(e)=>{updateState(e,false)}}>
            <div className='dlg-c ps-fx ps-btm col-view a-center bg-l-grey res-wth h-550-p pd-15 ovf-scrl hide-sb'>
                <p className='ft-sz-18 mg-b-10 cl-white pd-5-15 br-20 bx-shdw-blue bg-blue'>Wingo Rule</p>

                <div className='col-view pd-5 mg-b-50 w-100'>
                    <div className='ft-sz-16 cl-grey'>
                    1 minute 1 period, 55 seconds to order, 5 seconds waiting for the draw. It opens all day, the total number of purchases in a day is 1440 times.
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    1. Select Green: if the result shows 1,3,7,9 the pay out is (98*2)=196 ; If the result shows 5, the pay out is (98*1.5) 147
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    2. Select Red: if the result shows 2,4,6,8 the pay out is (98*2)=196 ; If the result shows 0, the pay out is (98*1.5) 147
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    3. Select Violet: if the result shows 0 or 5, the pay out is (98*4.5)=441
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    4. Select Number: if the result is the same as the number you selected, the pay out is (98*9)=882
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    5. Select Big: if the result shows 5,6,7,8,9 the pay out is (98*2)=196
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    6. Select Small: if the result shows 0,1,2,3,4 the pay out is (98*2)=196
                    </div>

                    <div className='ft-sz-16 mg-t-25 cl-grey'>
                    <span>GAME RULES:</span><br></br>
                    - It is not allowed to make 2-sided bets in 1 game period (For example: choosing green and red or big and small in the same period)
                    <br></br>
                    - To bet on numbers: the maximum total number that can be selected is 7 in 1 period (No more)
                    </div>
                </div>

                <div className='pd-10 ps-fx ps-btm res-wth bg-l-grey'>
                  <div className='bg-blue h-45-p ft-sz-15 pd-10 br-10 cl-white v-center w-100' onClick={(e)=>{updateState(e,"dismiss")}}>
                    <span>I GOT IT</span>
                  </div>
                </div>
                
            </div>
        </div>
    );
};

export default wingoRuleDialog;