import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../AuthTopBar/TopBar.module.css";
import general from "../../css/General.module.css";

const TopBar = () => {
  const navigate = useNavigate();

  return (
    <nav
      className={[general.respWidth, general.colView, styles.topBarView].join(
        " "
      )}
    >
      <div className={[styles.headView, general.viewCenter].join(" ")}>
        <Link onClick={() => navigate(-1)}>
          <img
            src={require("../../media/icons/angle_left_white_icon.png")}
            alt="icon"
          />
        </Link>

        <img src={require("../../media/icons/app_logo.png")} alt="logo" />
      </div>
    </nav>
  );
};

export default TopBar;
