import React from "react";
import { getStorage } from "../../modals/Storage";
import styles from "../ImportantNotice/Notice.module.css";

const ImportantNotice = () => {
  return (
    <div className={[styles.noticeView]}>
      <div className={[styles.noticeIconView]}>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-v-77cf39b0=""
        >
          <path
            d="M16.8 27.2H9.59999V40.4C9.59999 42.3883 11.2117 44 13.2 44C15.1883 44 16.8 42.3883 16.8 40.4V27.2Z"
            fill="#61A9FF"
            data-v-77cf39b0=""
          ></path>
          <path
            opacity="0.6"
            d="M19 12H9C7.3431 12 6 13.3431 6 15V25C6 26.6569 7.3431 28 9 28H20"
            fill="#61A9FF"
            data-v-77cf39b0=""
          ></path>
          <path
            opacity="0.6"
            d="M34 14H36C39.3137 14 42 16.6863 42 20C42 23.3137 39.3137 26 36 26H34"
            fill="#61A9FF"
            data-v-77cf39b0=""
          ></path>
          <path
            d="M33.7946 34C33.9081 34 34 33.9079 34 33.7943V6.20579C34 6.09214 33.9081 6 33.7946 6H30.3765C22.6575 6 16.4 12.2681 16.4 20C16.4 27.7319 22.6575 34 30.3765 34H33.7946Z"
            fill="#61A9FF"
            data-v-77cf39b0=""
          ></path>
        </svg>
      </div>

      <div className={[styles.noticeBox]}>{getStorage("impMessage")}</div>
    </div>
  );
};

export default ImportantNotice;
