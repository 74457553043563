import React from "react";
import styles from "../WinLoseDialog/dialog.module.css";

const winLoseDialog = ({ intentData, updateState }) => {
  return (
    <div
      className={[
        styles.dialogView,
        intentData.winDialogShow
          ? intentData.constWinDialogState != ""
            ? styles.viewCenter
            : ""
          : "",
      ].join(" ")}
      onClick={() => {
        updateState(false);
      }}
    >
      <div className={[styles.dialogViewContents]}>
        <img
          src={
            intentData.constWinDialogState == "win"
              ? require("../../icons/win_dialog.png")
              : require("../../icons/lose_dialog.png")
          }
        />

        <div
          className={[styles.dialogViewContentsHolder, styles.viewCenter].join(
            " "
          )}
        >
          <span
            className={[styles.posAbsLeftCenter, styles.dialogTitle].join(" ")}
          >
            {intentData.constWinDialogState == "win"
              ? "Congratulations"
              : "Sorry"}
          </span>

          <div
            className={[styles.posAbsLeftCenter, styles.dialogViewResults].join(
              " "
            )}
            dangerouslySetInnerHTML={{
              __html: intentData.constWinDialogResult,
            }}
          ></div>

          <div
            className={[
              styles.posAbsLeftCenter,
              styles.dialogWinLossDetail,
            ].join(" ")}
          >
            <span
              className={[
                styles.txtAlignCenter,
                intentData.constWinDialogState == "win"
                  ? styles.fontS15Px
                  : styles.fontS25Px,
              ].join(" ")}
            >
              {intentData.constWinDialogState == "win" ? "Winning" : "Lose"}
            </span>
            <span
              className={[
                styles.dialogWinLossAmount,
                intentData.constWinDialogState != "win" ? styles.hideView : "",
              ].join(" ")}
            >
              ₹{Number(intentData.constWinAmount).toLocaleString()}
            </span>
          </div>

          <span
            className={[
              styles.dialogPeriodDetails,
              styles.posAbsLeftCenter,
              styles.fontS12Px,
            ].join(" ")}
          >
            Period: {intentData.constPeriodId}
          </span>

          <div
            className={[
              styles.dialogDismissBtn,
              styles.posAbsLeftCenter,
              styles.viewCenter,
            ].join(" ")}
          >
            <span>X</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default winLoseDialog;
