import React from 'react'
import '../../MainStyle.css';

const noticeDialog = ({intentData,updateState}) => {

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 v-center bg-l-black ${intentData.noticeDialogShow ? 'activeDialog' : ''}`} onClick={()=>updateState(false)}>
            <div className='tst-content-2'>
                <div>
                  <div className='w-100 v-center pd-10 ft-sz-18 cl-white br-t-rl-5 bg-red-grad-8'>Notification</div>
                  <div className='pd-15 ft-sz-18'>{intentData.noticeTitle}</div>
                  <div className='pd-15 ft-sz-14'>{intentData.noticeNote}</div>
                </div>

                <div>
                  <div className='br-a-l-black'></div>
                
                  <div className='pd-15'>
                    <div className='w-100 v-center pd-5-10 ft-sz-18 cl-white br-20 bg-red-grad-8'>Confirm</div>
                  </div>
                </div>
            </div>
        </div>
    );
};

export default noticeDialog;