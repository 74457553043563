import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog';
import '../../MainStyle.css';
import { API_ACCESS_URL,redirectTo } from '../modals/Constants';
import { getStorage, setStorage } from '../modals/Storage';

function ChangeName(){
  const navigate = useNavigate();

    const [pageConst, setConstants] = useState({
        pageTitle: "Change Name",
        inNewName: "",
        isLoadingShow: false,
        toastDialogShow: false,
        toastMessage: "",
    });

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateToastDialogState = (data,msg) => {
      setConstants(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setConstants(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const onInputValChange = (source,data) =>{

      if(source=="newname"){
        setConstants(previousState => {
          return { ...previousState, inNewName: data }
        });
      }
    }

    const validateChangePassword = () => {
      const requestAPI = async (url,formData) => {
        try {
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Route': 'route-change-name',
              'AuthToken': getStorage("secret"),
            },
            body: JSON.stringify(formData),
          });

          const data = await res.json();
          updateLoadingStatus(false);

          if(data.status_code=="authorization_error"){
            updateToastDialogState(true,"Authorization Error!");
          }else if(data.status_code=="name_error"){
            updateToastDialogState(true,"Name is not correct! try again");
          }else if(data.status_code=="success"){
            updateToastDialogState(true,"Name Changed!");
            setStorage("uname", pageConst.inNewName);
          }else{
            updateToastDialogState(true,"Something went wrong! Please try again!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(pageConst.inNewName.length > 3 && pageConst.isLoadingShow==false){
        updateLoadingStatus(true);
        const formData = { USER_ID: getStorage("uid"),NEW_NAME: pageConst.inNewName};
        requestAPI(API_ACCESS_URL,formData);
      }
    }

    useEffect(() => {
      if(!getStorage("uid")){
        navigate('/login', { replace: true });
      }
    }, []);

    return (
      <div className="v-center bg-black ovf-hidden">
        <div className='h-100vh res-wth app-bg'>
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
          
          <div className="pr-v ovf-scrl-y hide-sb zoomAnimView">
            <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2=""/>

            <div className='col-view w-100 v-center pd-5-15 mg-b-15 mg-t-45'>

                <div className='cutm-inp-bx pd-5-15 br-5 mg-t-15'>
                    <img className="h-w-22" src={require('../icons/lock_icon.png')} />
                    <input type="text" className='mg-l-20 ft-sz-18 cutm-inp' autoComplete="new-password" placeholder='New Name' onChange={e => onInputValChange('newname',e.target.value)}></input>
                </div>

                <div className={`w-100 mg-t-20 h-50-p ft-sz-20 v-center br-10 ${pageConst.inNewName.length > 3 ? 'cl-drk-grey bg-red-grad-2' : 'cl-white bg-grey-2'}`} onClick={() => validateChangePassword()}>
                  <img className={`smpl-btn-l spin-anim ${pageConst.isLoadingShow==false ? 'hide-v' : ''}`} src={require('../icons/loader-icon.png')} />
                  <span className={`${pageConst.isLoadingShow==true ? 'hide-v' : ''}`}>Change Name</span>
                </div>

            </div>

          </div>

        </div>
      </div>
    );
}

export default ChangeName;