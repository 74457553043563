import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog';
import '../../MainStyle.css';
import { API_ACCESS_URL,getURLParam } from '../modals/Constants';
import { getCookie } from '../modals/Cookie';

function MyGameRecords(){
    const navigate = useNavigate();
    const [pageConst, setConstants] = useState({
        pageTitle: "Game Records",
        isLoadingShow: false,
        recordList: [],
    });

    const topBarClickAction = (data) =>{
    }

    const updateLoadingStatus = (data) => {
      setConstants(previousState => {
          return { ...previousState, isLoadingShow: data }
      });
    }

    const getCapitalLetter = (data) => {
      let returnVal = "";

      if(data=="red"){
        returnVal = "R";
      }else if(data=="green"){
        returnVal = "G";
      }else if(data=="violet"){
        returnVal = "V";
      }else{
        returnVal = data;
      }

      return returnVal;  
    }

    const getColourCodeVal = (data) => {
        let returnVal = "";

        if(data=="1" || data=="3" || data=="7" || data=="9"){
            returnVal = "g";
        }else if(data=="2" || data=="4" || data=="6" || data=="8"){
            returnVal = "r";
        }else if(data=="0"){
            returnVal = "rv";
        }else if(data=="5"){
            returnVal = "gv";
        }else if(data=="red"){
          returnVal = "r";
        }else if(data=="green"){
          returnVal = "g";
        }else if(data=="violet"){
          returnVal = "v";
        }

        return returnVal;
    }

    const updateGameRecords = (data) => {
      let tempData = [];

        for (let i = 0; i < data.length; i++) {          
            tempData.push(
            <div key={i} className="row-view mg-t-15 sb-view">
              <span className='ft-sz-14'>{data[i]['r_match_id']}</span>
              <span className='ft-sz-14'>{data[i]['r_match_open_price']}</span>
              <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getColourCodeVal(data[i]['r_match_result'])=="r" ? 'bg-red' : getColourCodeVal(data[i]['r_match_result'])=="g" ? 'bg-green' : getColourCodeVal(data[i]['r_match_result'])=="rv" ? 'bg-rv' : getColourCodeVal(data[i]['r_match_result'])=="gv" ? 'bg-gv' : 'bg-black'}`}>{getCapitalLetter(data[i]['r_match_result'])}</span>
            </div>)
        };

        updateLoadingStatus(false);

        setConstants(previousState => {
            return { ...previousState, recordList: tempData }
        });
    }

    function getGameRecords(project){
      updateLoadingStatus(true);

        const fecthApiData = async (url) => {
            try {
              const res = await fetch(url);
              const data = await res.json();
              updateLoadingStatus(false);
              updateGameRecords(data.data);
            } catch (error) {
              console.log(error);
            }
        };

        fecthApiData(API_ACCESS_URL+"load-game-records.php?PROJECT="+project);
    }
  
    useEffect(() => {
      if(getURLParam('GC')!=null && getURLParam('GC')!=""){
        let projectCode = getURLParam('GC');
        getGameRecords(projectCode);
      }
    }, []);

    return (
      <div className="v-center">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white">
          <TopBar intentData={pageConst} multiBtn={false} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>
          <LoadingDialog intentData={pageConst}/>

          <div className="game-manage-view col-view mg-t-45">

            <div className="col-view br-right-t br-left-t mg-b-15 bg-white">

              <div className='col-view min-h'>
                <div className="row-view sb-view bg-blue cl-white pd-0-10">
                  <span className='pd-10'>Period</span>
                  <span className='pd-10 mg-l-80'>Price</span>
                  <span className='pd-10 mg-l-20'>Result</span>
                </div>

                <div className='col-view pd-5-15'>
                  <div className="col-view">
                  {pageConst.recordList}
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    );
}

export default MyGameRecords;