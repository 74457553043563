import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import styles from "../LoadingDialog/dialog.module.css";

const LoadingDialog = ({ intentData }) => {
  return (
    <div
      className={[
        styles.dialogView,
        intentData ? styles.dialogViewActive : "",
      ].join(" ")}
    >
      <div className={[styles.dialogViewContents]}>
        <img src={require("../../media/icons/app_logo.png")} alt="icon" />

        <div className={[styles.dialogViewContentsLoading]}>
          <Player
            src={require("../../media/animations/loading_anim.json")}
            className="player"
            loop
            autoplay
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingDialog;
