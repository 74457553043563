import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import BettingDialog from '../dialogs/BettingDialog';
import LoadingDialog from '../dialogs/LoadingDialog';
import ToastDialog from '../dialogs/ToastDialog';
import WinDialog from '../dialogs/WinDialog';
import RuleDialog from '../dialogs/AndarBaharRuleDialog';
import '../../MainStyle.css';
import { setCookie,getCookie } from '../modals/Cookie';
import { API_ACCESS_URL } from '../modals/Constants';

function Circle(){

    const [remainingSec, setCount] = useState(60);
    const [gamePlayTime, setGamePlayTime] = useState(0);
    const [gamewaitingTime, setWaitingTime] = useState(0);
    const [rotateValues, setRotateValue] = useState(0);
    const degVal = [3652, 3956, 3899, 3757, 3918, 3700, 3671, 3813, 3776, 3737, 3633, 3832, 3794, 3880, 3852,
      3937, 3718, 3615, 3662, 3947, 3728, 3681, 3747, 3624, 3708, 3766, 3823, 3861, 3605, 3785, 3642, 3928, 3804,
      3890, 3908, 3842];

    const greenValues = [29, 20, 32, 35, 34, 28, 36, 27, 33, 30, 26, 23, 21, 25, 22, 19, 31, 24];
    const yellowValues = [2, 16, 5, 3, 14, 15, 12, 8, 13, 9, 4, 10, 17, 6, 7, 1, 11, 18];

    const [pageConst, setGameData] = useState({
        pageTitle: "Dragon Vs Tiger",
        period: "",
        gameCode: "WHEELOCITY",
        gameFolderName: "wheelocity",
        showCircleAnim: false,
        isControlEnable: true,
        gameSelectedColor: "",
        requestAccountData: true,
        isLoadingShow: false,
        bettingDialogShow: "false",
        bettingDialogTitle: "Join Green",
        bettingDialogTheme: "dlg-thm-green",
        ruleDialogShow: false,
        winDialogShow: false,
        toastDialogShow: false,
        toastMessage: "",
        tabActiveReord: "everyoneorder",
        tabActiveNumber: "1-10",
        gameWinDetails: [],
        gameRecordsList: [],
        mygameRecordsList: [],
        everyOnesOrderList: [],
        
    });

    const handleVisibilityChange = useCallback(() => {
      let isVisible = document.visibilityState === 'visible';
      if(isVisible){
        // getGameData(false);
      }
    }, []);

    const getColorName = (data) =>{
      let returnVal = "";

      if(greenValues.includes(Number(data))){
        returnVal = "green";
      }else if(yellowValues.includes(Number(data))){
        returnVal = "yellow";
      }else{
        returnVal = "red";
      }

      return returnVal;
    }

    const getAnimalName = (data) =>{
      let returnVal = "";

      if(data=="17" || data=="25" || data=="6" || data=="00" || data=="22" || data=="7" 
      || data=="19" || data=="1" || data=="31" || data=="11" || data=="24" || data=="18"){
        returnVal = "elephant";
      }else if(data=="20" || data=="16" || data=="32" || data=="5" || data=="35" || data=="3" 
      || data=="34" || data=="14" || data=="0" || data=="28" || data=="15" || data=="36"){
        returnVal = "tiger";
      }else if(data=="29" || data=="2"){
        returnVal = "crown";
      }else{
        returnVal = "cow";
      }

      return returnVal;
    }

    const updateActiveTabNumber = (data) =>{
      setGameData(previousState => {
        return { ...previousState, tabActiveNumber: data }
      });
    }

    const updatePeriod = (data) => {
        setGameData(previousState => {
          return { ...previousState, period: data[0].game_period_id }
        });
    }

    const updateMyBetRecords = (recentBetData) =>{
      let tempData = [];
      const recentBetArr = recentBetData.split(',');

      tempData.push(
        <div key={32} className="row-view mg-t-15 sb-view">
          <span className='ft-sz-14'>{recentBetArr[1]}</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(recentBetArr[2])}`}>{getCapitalLetter(recentBetArr[2])}</span>
          <span className='ft-sz-15'>{recentBetArr[3]+".00"}</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center`}></span>
          <span className={`w-65-p ft-sz-15 txt-a-end`} ></span>
        </div>);

      let finalData = [tempData].concat(pageConst.mygameRecordsList);

      setGameData(previousState => {
        return { ...previousState, mygameRecordsList: finalData }
      });
    }
    
    const updateWinDialogState = (e,state,extra) => {

      if(state=="force-close"){
        setGameData(previousState => {
          return { ...previousState, winDialogShow: false }
        });
      }else if(e!=null && e.target.className=="ps-fx h-100vh res-wth z-i--100 bg-l-black v-center activeDialog"){
        setGameData(previousState => {
          return { ...previousState, winDialogShow: state }
        });
      }else if(e==null && state==true){
        setGameData({...pageConst, winDialogShow: state});

        if(extra!="" && extra!=null){
          console.log(extra);

          setGameData(previousState => {
            return { ...previousState, gameWinDetails: extra }
          });
        }
      }
      
    }

    const updateGameControl = (data) => {
        setGameData(previousState => {
            return { ...previousState, isControlEnable: data }
        });
    }

    const updateReqAcntData = (data) => {
      setGameData(previousState => {
        return { ...previousState, requestAccountData: data }
    });
    }

    const updateActiveTab = (data) => {
        setGameData(previousState => {
            return { ...previousState, tabActiveReord: data }
        });
    }

    const updateLoadingStatus = (data) => {
      setGameData(previousState => {
          return { ...previousState, isLoadingShow: data }
      });
    }

    const getDialogThemeClassName = (data) =>{
      let returnVal = "dlg-thm-orange";
      if(data=="A"){
        returnVal = "dlg-thm-violet";
      }else if(data=="B"){
        returnVal = "dlg-thm-red";
      }

      return returnVal;
    }
    
    const updateBettingDialogState = (e,data,selected,title) => {
      if(selected!=""){
        setGameData(previousState => {
          return { ...previousState, gameSelectedColor: selected }
        });
      }

      if(e!=null && e.target.className=="ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog"){
        setGameData(previousState => {
          return { ...previousState, bettingDialogShow: data }
        });
      }else if(data=="true" && pageConst.isControlEnable){
        setGameData(previousState => {
          return { ...previousState, bettingDialogShow: data }
        });

        setGameData(previousState => {
          return { ...previousState, bettingDialogTitle: title }
        });

        setGameData(previousState => {
          return { ...previousState, bettingDialogTheme: getDialogThemeClassName(selected) }
        });
        
      }else if(data=="dismiss"){
        setGameData(previousState => {
          return { ...previousState, bettingDialogShow: "false" }
        });
      }
    }

    const topBarClickAction = (data) =>{
      if(data=="multiBtn1"){

      }else{
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: true }
        });
      }
    }

    const updateGameRuleDialog = (e,data) => {
      if(e!=null && e.target.className=="ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: data }
        });
      }else if(data=="dismiss"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: false }
        });
      }else if(data=="true"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: true }
        });
      }
    } 

    const updateToastDialogState = (data,msg) => {
      setGameData(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setGameData(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const getCapitalLetter = (data) => {
       let returnVal = "";

       if(data=="red"){
         returnVal = "R";
       }else if(data=="green"){
         returnVal = "G";
       }else if(data=="violet"){
         returnVal = "V";
       }else{
         returnVal = data;
       }

       return returnVal;  
    }

    const getBetBgClass = (data) => {
        let returnVal = "";

        if(data=="A"){
            returnVal = "bg-blue";
        }else if(data=="B"){
            returnVal = "bg-red";
        }else if(data=="T"){
            returnVal = "bg-orange";
        }

        return returnVal;
    }

    const updateGameRecords = (data,resetStatus) => {
        let tempData = [],currentPeriod="",numNeedToRemove="";
        for (let i = 0; i < data.length; i++) {
          if(i==0){

            if(resetStatus){
              console.log(data[i]['match_color']+"/Degree: "+degVal[data[i]['match_color']-1]+"/ Circle Name: "+getAnimalName(data[i]['match_color'])+"/Colour: "+getColorName(data[i]['match_color']));
                            
              showCircleAnim(degVal[data[i]['match_color']-1]);
            }

            currentPeriod = Number(data[i]['period_id'].slice(-3))+1;
            if(currentPeriod < 10){
              currentPeriod = "00"+currentPeriod;
            }else if(currentPeriod < 100){
              currentPeriod = "0"+currentPeriod;
            }
            numNeedToRemove = 10-Number(data[i]['period_id'].slice(-1));
          }
  
          tempData.push(
            <div key={i}>
                <div className={`col-view v-center pd-3 ft-sz-14 br-5 cl-white ${getColorName(data[i]['match_color'])=='red' ? 'bg-red' : getColorName(data[i]['match_color'])=='green' ? 'bg-green' : 'bg-yellow'}`}>{data[i]['match_color']}</div>

                <div className='col-view v-center pd-3 mg-t-5 ovf-hidden br-5 bg-white'>
                  <img className='h-15-p' src={require(`../icons/CIRCLE/${getAnimalName(data[i]['match_color'])=='cow' ? '3.png' : getAnimalName(data[i]['match_color'])=='tiger' ? '4.png' : getAnimalName(data[i]['match_color'])=='elephant' ? '5.png' : '6.png'}`)} alt="icon" />
                </div>
            </div>)
        };

        setGameData(previousState => {
          return { ...previousState, gameRecordsList: tempData }
        });

    }

    const updateEveryOnesOrder = (data) => {
      setGameData(previousState => {
        return { ...previousState, everyOnesOrderList: data }
      });
    }

    const getFixedDecimalVal = (data) => {
      return Number(data).toFixed();
    }

    function shuffle(array) {
      let currentIndex = array.length,  randomIndex;
    
      // While there remain elements to shuffle.
      while (currentIndex > 0) {
    
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
    
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
    
      return array;
    }

    const updateMyGameRecords = (data) => {
      let tempData = [];

        for (let i = 0; i < data.length; i++) {

          if(i==0 && getCookie("recentbet")){
            const recentBetArr = getCookie("recentbet").split(',');

            if(data[0]['m_period_id']==recentBetArr[1] && data[0]['m_project']==recentBetArr[0] && data[0]['m_status']=="profit"){
              setCookie("recentbet", "", 5);
              console.log(data[i]);
              updateWinDialogState(null, true, data[i]);
            }
          }

          let tempMatchReslt = "";
          if(data[i]['m_status']=="profit"){
            if(Number(data[i]['m_profit']) >= 1000000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else if(Number(data[i]['m_profit']) >= 100000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else if(Number(data[i]['m_profit']) >= 10000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else{
              tempMatchReslt = "+₹"+Number(data[i]['m_profit']);
            }
          }else if(data[i]['m_status']=="loss"){
            if(Number(data[i]['m_cost']) >= 1000000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else if(Number(data[i]['m_cost']) >= 100000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else if(Number(data[i]['m_cost']) >= 10000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else{
              tempMatchReslt = "-₹"+Number(data[i]['m_cost']);
            }
          }
          
          tempData.push(
            <div key={i} className="row-view mg-t-15 sb-view">
              <span className='ft-sz-14'>{data[i].m_period_id}</span>
              <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(data[i]['m_color'])}`}>{getCapitalLetter(data[i]['m_color'])}</span>
              <span className='ft-sz-15'>{data[i]['m_cost']+".00"}</span>
              <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(data[i]['m_result'])}`}>{getCapitalLetter(data[i]['m_result'])}</span>
              <span className={`w-65-p ft-sz-15 txt-a-end ${data[i]['m_status']=="loss" ? 'cl-red' : 'cl-green'}`} >{tempMatchReslt}</span>
            </div>)
        };

        setGameData(previousState => {
            return { ...previousState, mygameRecordsList: tempData }
        });
    }

    const getRandBool = () => {
      let returnVal = false;

      let status=Math.round(Math.random())
      if(status==1){
        returnVal = true;
      }

      return returnVal;
    }

    const getRandomNum = (min, max, type) => {
      if(type=="multi"){
        return Math.round((Math.random()*(max-min)+min)/10)*10;
      }else{
        return Math.floor(Math.random() * (max - min) + min);
      }
    }

    const updateEveryOnesOrders = (periodId,remainSec,controlEnabled,everyoneorder) => {
      if(periodId!=""){

        let tempData = [];
        
        if(controlEnabled==true && getRandBool()){

          if(everyoneorder.length > 0){
            tempData = everyoneorder;
          }
  
          let randBetId = getRandomNum(100,999, '');
          let randBetAmnt = 10;

          if(getRandBool()){
            randBetAmnt = getRandomNum(10,300, 'multi');
          }else{
            randBetAmnt = getRandomNum(10,2000, 'multi');
          }

          let randBetNum = "";

          if(getRandBool()){
            randBetNum = "A"
          }else{
            randBetNum = "B";
          }
  
          tempData.push(
            <div key={remainSec} className="row-view mg-t-15 sb-view">
              <span className='ft-sz-14'>{periodId}</span>
              <span className='ft-sz-14'>***{randBetId}</span>
              <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(randBetNum)}`}>{getCapitalLetter(randBetNum)}</span>
              <span className="ft-sz-14 w-50-p">₹{randBetAmnt}</span>
            </div>)

          updateEveryOnesOrder(tempData.reverse());
        }
        
      }
    }

    function getGameData(resetStatus){
      updateLoadingStatus(true);

      const fecthApiData = async (url) => {
        
        try {
          const res = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'AuthToken': getCookie("secret"),
            }
          });

          const data = await res.json();

          updateLoadingStatus(false);

          if(data.status_code=="success"){
            updatePeriod(data.gamedata);
            setCount(data.gamedata[0].game_remain_seconds);
            setGamePlayTime(data.gamedata[0].game_play_time);
            setWaitingTime(data.gamedata[0].game_disable_time);
            updateGameRecords(data.matchrecords,resetStatus);
            updateMyGameRecords(data.mymatchrecords);
          }else{
            updateToastDialogState(true, "Something went wrong! Please try again or login!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          console.log(error);
        }
      };

      fecthApiData(API_ACCESS_URL+"games-api/"+pageConst.gameCode.toLowerCase()+"/setup-game.php?USER_ID="+getCookie("uid")+"&PROJECT="+pageConst.gameCode);
    }

    function setUpTimer(resetStatus){
        if(pageConst.requestAccountData==true){
            updateReqAcntData(false);
            getGameData(resetStatus);
        }
    }


    const hideCircleAnim = () =>{
      setGameData(previousState => {
        return { ...previousState, showCircleAnim: false }
      });

      setRotateValue(0);
    }

    const showCircleAnim = (animDegree) =>{
      setGameData(previousState => {
        return { ...previousState, showCircleAnim: true }
      });

      if(animDegree!=""){
        setRotateValue(animDegree);
      }
    }

    const getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {

        document.addEventListener("visibilitychange", handleVisibilityChange);
        // setUpTimer(false);

        const interval = setInterval(() => {
            if(remainingSec >= 0){
                setCount(remainingSec - 1);

                if(remainingSec <= 0){
                    getGameData(true);
                    setCount(60);
                    updateEveryOnesOrder([]);
                }else{

                    if(remainingSec < gamewaitingTime && pageConst.isControlEnable == true){
                      updateGameControl(false);
                    }else if (remainingSec > gamewaitingTime && remainingSec < gamePlayTime-12  && pageConst.isControlEnable == false) {
                      hideCircleAnim();
                      updateGameControl(true);
                    }

                    updateEveryOnesOrders(pageConst.period,remainingSec,pageConst.isControlEnable,pageConst.everyOnesOrderList);
                }
            }
        }, 1000);

        //Clearing the interval
        return () => {
          clearInterval(interval);
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        }

    }, [remainingSec]);

    return (
      <div className="v-center">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-dragontiger">
           <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="Rule" updateState={topBarClickAction}/>
           <LoadingDialog intentData={pageConst}/>
           <WinDialog intentData={pageConst} updateState={updateWinDialogState}/>
           <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
           <RuleDialog intentData={pageConst} updateState={updateGameRuleDialog}/>
           <BettingDialog intentData={pageConst} updateState={updateBettingDialogState} toastUpdate={updateToastDialogState} updateMyBetRecords={updateMyBetRecords}/>

           <div className="game-manage-view col-view mg-t-45">

              <div className="col-view">

              <div className='row-view sb-view w-100 pd-5-10 mg-t-15'>

                {/* {pageConst.gameRecordsList} */}

               </div>

               <div className={`col-view a-center pd-5 mg-t-15`}>

                <div className='ps-rl w-100 col-view v-center ovf-hidden'>

                  <div className='w-100 row-view sb-view z-i-100'>
                    <div className='ps-rl dragonTigerAnimLR'>
                      <img style={{height: '90px'}} src={require(`../icons/DRAGONVSTIGER/5.png`)} alt="icon"/>
                      <img className='ps-ab ps-tp-50 ps-lf-50 t-form-xy' style={{height: '55px', left: '-60px',top: '28px',transform: 'rotate(210deg)'}} src={require(`../icons/DRAGONVSTIGER/10.png`)} alt="icon"/>
                    </div>

                    <img style={{height: '40px'}} src={require(`../icons/DRAGONVSTIGER/11.png`)} alt="icon"/>
                    
                    <div className='ps-rl dragonTigerAnimRL'>
                      <img style={{height: '90px'}} src={require(`../icons/DRAGONVSTIGER/4.png`)} alt="icon"/>
                      <img className='ps-ab ps-tp-50 ps-lf-50 t-form-xy' style={{height: '55px', left: '82px',top: '55px'}} src={require(`../icons/DRAGONVSTIGER/9.png`)} alt="icon"/>
                    </div>
                  </div>

                  <div className='ps-rl mg-t--20'>
                    <img className='ps-ab w-100 z-i-98' style={{marginTop: '-37px'}} src={require(`../icons/DRAGONVSTIGER/2.png`)} alt="icon"/>
                    <img className={`ps-rl w-100 min-h-100 z-i-99`} src={require(`../icons/DRAGONVSTIGER/1.png`)} alt="icon"/>

                    <div className={`ps-ab ps-tp-50 ps-lf-50 t-form-xy w-85 row-view sb-view z-i-99`}>
                      <div className='h-140-p w-100 v-center col-view br-5' style={{background: '#a3b0e4'}} onClick={()=>updateBettingDialogState(null,'true','dragon','Dragon')}>
                        <img className='h-40-p opac-05' style={{transform: 'rotate(310deg)'}} src={require(`../icons/DRAGONVSTIGER/5.png`)} alt="icon" />
                        <p className='opac-05 ft-wgt-b mg-t-5'>Dragon</p>
                      </div>

                      <div className='h-140-p w-100 v-center col-view br-5 mg-l-10' style={{background: '#7dc468'}}>
                        <img className='h-40-p opac-05' src={require(`../icons/DRAGONVSTIGER/8.png`)} alt="icon" />
                        <p className='opac-05 ft-wgt-b mg-t-5'>Tie</p>
                      </div>

                      <div className='h-140-p w-100 v-center col-view br-5 mg-l-10' style={{background: '#f1ad6c'}}>
                        <img className='h-40-p opac-05' src={require(`../icons/DRAGONVSTIGER/4.png`)} alt="icon" />
                        <p className='opac-05 ft-wgt-b mg-t-5'>Tiger</p>
                      </div>
                    </div>
                    {/* <img className={`ps-ab ps-tp-50 ps-lf-50 t-form-xy w-85`} src={require(`../icons/DRAGONVSTIGER/3.png`)} alt="icon"/> */}
                  </div>

                  <div className='row-view sb-view w-100 pd-5-10 mg-t-15'>
                    <div className='h-w-45 v-center ft-wgt-b br-50' style={{background: '#a3b0e4'}}>D</div>
                    <div className='h-w-45 v-center ft-wgt-b br-50' style={{background: '#f1ad6c'}}>T</div>
                    <div className='h-w-45 v-center ft-wgt-b br-50' style={{background: '#a3b0e4'}}>D</div>
                    <div className='h-w-45 v-center ft-wgt-b br-50' style={{background: '#7dc468'}}>
                      T<span className='ft-sz-12'>ie</span>
                    </div>
                    <div className='h-w-45 v-center ft-wgt-b br-50' style={{background: '#a3b0e4'}}>D</div>
                    <div className='h-w-45 v-center ft-wgt-b br-50' style={{background: '#a3b0e4'}}>D</div>
                    <div className='h-w-45 v-center ft-wgt-b br-50' style={{background: '#a3b0e4'}}>D</div>
                  </div>
                  
                </div>
                
               </div>

               {/* <div className={`row-view mg-t-10 pd-15 ${pageConst.isControlEnable ? '' : 'disable-view'}`}>

               <div className="col-view a-center w-100 pd-10 cl-white br-10 bg-blue bx-shdw-blue" onClick={()=>updateBettingDialogState(null,'true','yellow','Yellow')}>
                  <span>DRAGON</span>
                  <span className='mg-t-5 cl-l-white'>1:2</span>
                </div>

                <div className="col-view a-center w-100 pd-10 cl-white br-10 bg-yellow bx-shdw-red mg-l-10" onClick={()=>updateBettingDialogState(null,'true','red','Red')}>
                  <span>TIE</span>
                  <span className='mg-t-5 cl-l-white'>1:12</span>
                </div>

                <div className="col-view a-center w-100 pd-10 cl-white br-10 bg-yellow bx-shdw-yellow mg-l-10" onClick={()=>updateBettingDialogState(null,'true','green','Green')}>
                  <span>TIGER</span>
                  <span className='mg-t-5 cl-l-white'>1:2</span>
                </div>
                
               </div> */}

              </div>

              <div className="col-view br-right-t br-left-t mg-t-25 mg-b-15 bg-white">

                <div className="tab-slct-v w-100">
                   <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActiveReord=="everyoneorder" ? 'active' : ''}`} onClick={()=>updateActiveTab('everyoneorder')}>Everyone's Order</div>
                   <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActiveReord=="myorder" ? 'active' : ''}`} onClick={()=>updateActiveTab('myorder')}>My Order</div>
                </div>

                <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord!="everyoneorder" ? 'hide-v' : ''}`}>
                  <div className="row-view sb-view">
                    <span>Period</span>
                    <span>User</span>
                    <span>Select</span>
                    <span>Point</span>
                  </div>

                   <div className="col-view mg-t-15 fd-up-anim">
                    {pageConst.everyOnesOrderList}
                   </div>
                </div>

                <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord!="myorder" ? 'hide-v' : ''}`}>
                  <div className="row-view sb-view">
                    <span className='ft-sz-14'>Period</span>
                    <span className='ft-sz-14 mg-l-30'>Select</span>
                    <span className='ft-sz-14'>Point</span>
                    <span className='ft-sz-14'>Result</span>
                    <span className='ft-sz-14'>Amount</span>
                  </div>

                   <div className="col-view mg-t-15">
                    {pageConst.mygameRecordsList}
                   </div>

                   <Link className="w-100 v-center pd-10 mg-t-15 br-15 bg-grey txt-deco-n" to={"/myorders"}>More</Link>
                </div>

              </div>

           </div>
        </div>
      </div>
    );
}

export default Circle;