export function setSessionStorage(sname,svalue) {
    return sessionStorage.setItem(sname, svalue);
 }
 
 export function getSessionStorage(sname) {
    return sessionStorage.getItem(sname);
 }
 
 export function deleteSessionStorage(){
    sessionStorage.clear();
     return true;
 }