import React, { useEffect } from "react";
import styles from "../ToastDialog/toast.module.css";

const ToastDialog = ({ intentData, updateState }) => {
  useEffect(() => {
    if (intentData.toastDialogShow) {
      setTimeout(() => updateState(false, 2, ""), 2000);
    }
  }, [intentData]);

  return (
    <div
      className={[
        styles.dialogView,
        !intentData.toastDialogShow ? styles.dialogViewDeActive : "",
      ].join(" ")}
      onClick={() => updateState(false, "")}
    >
      <div className={[styles.dialogContentView]}>
        {intentData.toastDialogMode == 1 ? (
          <img src={require("../../media/icons/check_white_icon.png")} />
        ) : (
          <img src={require("../../media/icons/warning_white_icon.png")} />
        )}
        <div>{intentData.toastMessage}</div>
      </div>
    </div>
  );
};

export default ToastDialog;
