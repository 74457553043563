import React from "react";
import { Link } from "react-router-dom";
import general from "../../css/General.module.css";
import styles from "../BottomNavbar/Navbar.module.css";

const BottomNavbar = ({ activeBar }) => {
  return (
    <div className={[general.rowView]}>
      <nav className={[general.respWidth, styles.navBarView].join(" ")}>
        <Link
          className={[
            styles.navItemView,
            styles.transition05,
            activeBar == "home" ? styles.navItemAnimate : "",
          ].join(" ")}
          to={"/home"}
        >
          <img
            src={require("../../media/icons/home_active_icon.png")}
            className={`h-w-26 ${activeBar != "home" ? "hide-v" : ""}`}
            alt="icon"
          />
          <img
            src={require("../../media/icons/home_icon.png")}
            className={`h-w-26 ${activeBar == "home" ? "hide-v" : ""}`}
            alt="icon"
          />
          <p>Home</p>
        </Link>

        <Link
          className={[
            styles.navItemView,
            styles.transition05,
            activeBar == "activity" ? styles.navItemAnimate : "",
          ].join(" ")}
          to={"/activity"}
        >
          <img
            src={require("../../media/icons/activity_active_icon.png")}
            className={`h-w-26 ${activeBar != "activity" ? "hide-v" : ""}`}
            alt="icon"
          />
          <img
            src={require("../../media/icons/activity_icon.png")}
            className={`h-w-26 ${activeBar == "activity" ? "hide-v" : ""}`}
            alt="icon"
          />
          <p>Activity</p>
        </Link>

        <Link
          className={[
            styles.navItemView,
            styles.transition05,
            activeBar == "promotion" ? styles.navItemAnimate : "",
          ].join(" ")}
          to={"/promotion"}
        >
          <div className={[styles.navPromotionItemView]}>
            <div></div>
            <img
              src={require("../../media/icons/diamond_icon.png")}
              alt="icon"
            />
          </div>
          <p>Promotion</p>
        </Link>

        <Link
          className={`nav-col ${
            activeBar == "recharge" ? "navBtnAnimate" : ""
          }`}
          to={"/recharge"}
        >
          <img
            src={require("../../media/icons/wallet_active_icon.png")}
            className={`h-w-26 ${activeBar != "recharge" ? "hide-v" : ""}`}
            alt="icon"
          />
          <img
            src={require("../../media/icons/wallet_icon.png")}
            className={`h-w-26 ${activeBar == "recharge" ? "hide-v" : ""}`}
            alt="icon"
          />
          <p>Wallet</p>
        </Link>

        <Link
          className={`nav-col ${activeBar == "account" ? "navBtnAnimate" : ""}`}
          to={"/account"}
        >
          <img
            src={require("../../media/icons/account_active_icon.png")}
            className={`h-w-26 ${activeBar != "account" ? "hide-v" : ""}`}
            alt="icon"
          />
          <img
            src={require("../../media/icons/account_icon.png")}
            className={`h-w-26 ${activeBar == "account" ? "hide-v" : ""}`}
            alt="icon"
          />
          <p>Account</p>
        </Link>
      </nav>
    </div>
  );
};

export default BottomNavbar;
