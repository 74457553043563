import React, { useEffect, useState } from "react";
import TopBar from "../other-components/TopBar";
import ToastDialog from "../dialogs/ToastDialog/toast";
import "../../MainStyle.css";
import { API_ACCESS_URL, getURLParam } from "../modals/Constants";
import { Link, useNavigate } from "react-router-dom";
import { deleteStorage, getStorage, setStorage } from "../modals/Storage";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";

function Withdraw() {
  const navigate = useNavigate();
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [requiredPlayBalance, setRequiredPlayBalance] = useState("0.00");
  const [withdrawableBalance, setWithdrawableBalance] = useState("0.00");

  const [pageConst, setConstants] = useState({
    pageTitle: "Withdraw",
    availBalance: 0,
    inWithdrawAmount: "",
    recordList: [],
  });

  const [toastDialogConst, setToastDialogConst] = useState({
    toastDialogShow: false,
    toastDialogMode: 2,
    toastMessage: "",
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate("/withdraw", { replace: false });
    } else if (data == "multiBtn2") {
      navigate("/withdrawrecords", { replace: false });
    }
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const updateToastDialogState = (data, mode, msg) => {
    // mode: 1 / success
    // mode: 2 / warning

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogMode: mode };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const onInputValChange = (source, data) => {
    if (source == "withdrawamount") {
      if (data.indexOf(".") == -1) {
        if (Number(data) >= getStorage("minwithdraw")) {
          setInValCorrect(true);
        } else {
          setInValCorrect(false);
        }

        setConstants((previousState) => {
          return { ...previousState, inWithdrawAmount: data };
        });
      }
    }
  };

  const updatePrimaryCard = (data, available) => {
    let tempData = [];

    if (available) {
      tempData.push(
        <Link
          key={0}
          className={[
            general.width100,
            general.textDecoNone,
            general.rowView,
            general.pd15px10px,
            general.mgT10px,
            general.borderRadius10px,
            general.bgCustomColor5,
          ].join(" ")}
          to={"/bankcards"}
        >
          <div
            className={[
              general.colView,
              general.alignCenter,
              general.pd5px10px,
            ].join(" ")}
          >
            <img
              className={[general.height30px]}
              src={require("../media/icons/bank_selected_icon.png")}
              alt="icon"
            />
            <span
              className={[
                general.width80px,
                general.fontS14Px,
                general.textAlignCenter,
                general.colorWhite,
                general.mgT10px,
                general.textOverflowDots,
              ].join(" ")}
            >
              {data[0]["c_bank_name"]}
            </span>
          </div>
          <span
            className={[
              general.height30px,
              general.width2px,
              general.bgWhite,
            ].join(" ")}
          ></span>

          <span
            className={[
              general.rowView,
              general.spaceBetween,
              general.width100,
              general.pd10px15px,
              general.fontS16Px,
              general.colorWhite,
              general.textAlignCenter,
            ].join(" ")}
          >
            {data[0]["c_bank_account"]}

            <img
              className={[general.heightWidth18px, general.mgL10px].join(" ")}
              src={require("../media/icons/angle_right_white_icon.png")}
              alt="icon"
            />
          </span>
        </Link>
      );
    } else {
      tempData.push(
        <Link
          key={0}
          className={[
            general.width100,
            general.textDecoNone,
            general.colView,
            general.alignCenter,
            general.pd15px10px,
            general.mgT10px,
            general.borderRadius10px,
            general.bgCustomColor5,
          ].join(" ")}
          to={"/bankcards"}
        >
          <img
            className={[general.height50px]}
            src={require("../media/icons/add_icon.png")}
            alt="icon"
          />
          <span
            className={[
              general.colorLightWhite,
              general.fontS16Px,
              general.mgT10px,
            ].join(" ")}
          >
            Add a bank account number
          </span>
        </Link>
      );
    }

    updateLoadingStatus(false);

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  function getPrimarBankCard() {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            route: "route-get-primary-bankcard",
            AuthToken: getStorage("secret"),
          },
        });
        const data = await res.json();
        setStorage("balance", data.account_balance);
        setRequiredPlayBalance(data["required_play_balance"]);
        setWithdrawableBalance(data["withdrawable_balance"]);

        updateLoadingStatus(false);
        if (data.status_code == "success") {
          updatePrimaryCard(data.data, true);
        } else {
          updatePrimaryCard(null, false);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    fecthApiData(API_ACCESS_URL + "?USER_ID=" + getStorage("uid"));
  }

  const confirmWithdrawBalance = () => {
    const fecthApiData = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-withdraw-request",
            AuthToken: getStorage("secret"),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "gameplay_required") {
          updateToastDialogState(
            true,
            2,
            "You need to bet ₹" +
              data.required_play_balance +
              " to be able to withdraw"
          );
        } else if (data.status_code == "insufficient_balance") {
          updateToastDialogState(
            true,
            2,
            "Insufficient Balance! Please try again!"
          );
        } else if (data.status_code == "no_premium") {
          updateToastDialogState(
            true,
            2,
            "You need to recharge first ! Please try again!"
          );
        } else if (data.status_code == "primary_bankcard_error") {
          updateToastDialogState(true, 2, "Makesure to create a bankcard!");
        } else if (data.status_code == "success") {
          setStorage("balance", data.account_balance, 30);
          updateToastDialogState(true, 2, "Withdraw Successful!");
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if (isInputValCorrect && showLoading == false) {
      updateLoadingStatus(true);
      const formData = {
        USER_ID: getStorage("uid"),
        WITHDRAW_AMOUNT: pageConst.inWithdrawAmount,
      };
      fecthApiData(API_ACCESS_URL, formData);
    }
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      if (deleteStorage()) {
        navigate("/login", { replace: true });
      }
    } else {
      let availBalance = getStorage("balance");

      getPrimarBankCard();

      setConstants((previousState) => {
        return { ...previousState, availBalance: availBalance };
      });
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2="Records"
          updateState={topBarClickAction}
        />
        <ToastDialog
          intentData={toastDialogConst}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={showLoading} />

        <div className="zoomAnimView">
          <div
            className={[
              general.colView,
              general.pd10px15px,
              general.mgT50px,
            ].join(" ")}
          >
            <div
              className={[
                general.width100,
                general.colView,
                styles.withdrawlViewCard,
                general.bgCustomColor1,
              ].join(" ")}
            >
              <span className="cl-white">Available Balance</span>
              <span className="cl-white ft-sz-25 ft-wgt-500">
                ₹{Number(pageConst.availBalance).toLocaleString()}
              </span>

              <div className="w-100 row-view sb-view mg-t-25">
                <img
                  className="h-25-p"
                  src={require("../icons/chip_icon.png")}
                />
                <span className="cl-white">******</span>
              </div>
            </div>

            <div className={[general.colView, general.mgT10px].join(" ")}>
              {pageConst.recordList}
            </div>

            <div
              className={[
                general.width100,
                general.colView,
                styles.withdrawlOperateView,
                general.bgCustomColor5,
              ].join(" ")}
            >
              <div className={[styles.withdrawlInputView].join(" ")}>
                <span>₹</span>
                <input
                  type="number"
                  className={[general.inputBox, general.hideInputBoxFocus].join(
                    " "
                  )}
                  value={pageConst.inWithdrawAmount}
                  placeholder="Please enter the amount"
                  onChange={(e) =>
                    onInputValChange("withdrawamount", e.target.value)
                  }
                ></input>
              </div>

              <div
                className={[
                  general.rowView,
                  general.spaceBetween,
                  general.fontS13Px,
                  general.mgT15px,
                ].join(" ")}
              >
                <div className={[general.colorLightWhite]}>
                  Withdrawable balance:{" "}
                  <span
                    className={[general.mgL5px, general.colorCustom1].join(" ")}
                  >
                    ₹{withdrawableBalance}
                  </span>
                </div>
                {/* <span>All</span> */}
              </div>

              <div
                className={[
                  general.rowView,
                  general.spaceBetween,
                  general.fontS13Px,
                  general.mgT10px,
                ].join(" ")}
              >
                <span className={[general.colorLightWhite]}>
                  Withdrawl amount received:
                </span>
                <span className={[general.colorCustom1]}>
                  {pageConst.inWithdrawAmount > 0
                    ? "₹" + pageConst.inWithdrawAmount
                    : ""}
                </span>
              </div>

              <div
                className={[
                  general.height45px,
                  general.width100,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS20Px,
                  general.mgT30px,
                  general.borderRadius20px,
                  isInputValCorrect
                    ? general.bgCustomColor2
                    : general.bgDisable,
                ].join(" ")}
                onClick={() => confirmWithdrawBalance()}
              >
                Withdrawal
              </div>

              <div
                className={[general.colView, styles.withdrawlRulesView].join(
                  " "
                )}
              >
                <p>
                  Need to bet <span>₹{requiredPlayBalance}</span> to be able to
                  withdraw
                </p>
                <p>
                  Inday Remaining Withdrawal Times <span>3</span>
                </p>
                <p>
                  Withdrawal amount range{" "}
                  <span>₹{getStorage("minwithdraw")}-₹10,00,000.00</span>
                </p>
                <p>
                  Please confirm your beneficial account information before
                  withdrawing. If your information is incorrect, our company
                  will not be liable for the amount of loss
                </p>
                <p>
                  If your beneficial information is incorrect, please contact
                  customer service
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Withdraw;
